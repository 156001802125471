import React from 'react';
import {
  Col, Divider, PageHeader, Row, Typography,
} from 'antd';
import { BaseLayout } from '../../components/layout/base/base-layout';
import { SEO } from '../../components/layout/seo';
import { useShop } from '../../graphql/static-hooks/useShop';

const {
  Title,
  Paragraph,
  Text,
} = Typography;

const PrivacyPage = () => {
  const shop = useShop();

  return (
    <BaseLayout>
      <SEO title="Data & Privacy" />
      <Row align="center">
        <Col flex="650px">
          <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title="Privacy Policy & Data Protection"
          >
            <Divider />
            <Paragraph>
              This privacy policy contains all the necessary information that must be provided to
              the Users at the time of collecting their personal data through any of the forms on
              the website, such as those related to the purposes, storage periods and legal basis of
              lawfulness of processing, how they are used, communicated and the rights of the Users.
            </Paragraph>
            <Paragraph>
              These policies have been drawn up in accordance with Regulation (EU) 2016/679 of the
              European Parliament and of the Council of 27 April 2016, and with current data
              protection legislation in Spain.
            </Paragraph>
            <Paragraph>
              <Text strong>
                Data controller
              </Text>
            </Paragraph>
            <Paragraph>
              {shop.companyAddress?.companyName || 'Destination Global S.L.'}
              {' '}
              (
              {shop.name}
              ) is responsible for the collection and
              use of your personal data as indicated in this privacy policy. Our head office is in
              Carrer Ramón Turro 100-104, ático 3, 08005 Barcelona, Spain, with VAT number
              B-64497027.

            </Paragraph>
            <Divider />
            <Paragraph>
              <Text strong>
                Principles applied to the processing of personal data

              </Text>
            </Paragraph>

            <Paragraph>
              The following principles are applied by
              {' '}
              {shop.name}
              {' '}
              and by anyone on its staff who has
              access to data. The personal data will always be:
            </Paragraph>

            <Paragraph>
              <ul>
                <li>
                  processed in a lawful, fair and transparent manner.
                </li>
                <li>
                  collected for specified, explicit and legitimate purposes and not be further
                  processed in a manner incompatible with those purposes.

                </li>
                <li>
                  adequate, relevant and limited to what is necessary in relation to the purposes
                  for which they are processed.
                </li>
                <li>
                  accurate and, if necessary, kept up to date, and we guarantee that all reasonable
                  steps are taken to ensure that any information which is inaccurate in relation to
                  the purposes for which it is provided is erased or rectified.
                </li>
                <li>
                  stored for no longer than is necessary for the purposes of the processing.
                </li>
                <li>
                  processed with integrity and confidentiality, in such a way as to guarantee
                  adequate security for them.
                </li>
              </ul>
            </Paragraph>
            <Divider />
            <Paragraph>
              <Text strong>
                Personal data processed and purpose
              </Text>
            </Paragraph>
            <Paragraph>
              The personal data provided must be accurate and complete and must be kept up to date.
              Data logging using the contact form is mandatory, at least those marked as such, and
              will imply that you have been informed and have given your consent to the contents of
              this privacy policy. Failure to provide such information, or the provision of
              incorrect data, will make it impossible for us to correctly handle the requests made.
            </Paragraph>
            <Paragraph>
              On our website we might collect the following categories of personal data:
            </Paragraph>
            <Paragraph>
              <ul>
                <li>
                  Information related to subscriptions to newsletters, e-books and commercial
                  communications, such as name, surname, email, telephone, language and country.
                  This personal data is stored in our databases for the management of business
                  relations and in
                  "
                  <a
                    href="https://mailchimp.com/es/help/about-the-general-data-protection-regulation/"
                  >
                    Mailchimp
                  </a>
                  "
                  , our promotional campaigns manager, a company located in the United States and
                  adhered to
                  "
                  <a href="https://mailchimp.com/en/help/mailchimp-european-data-transfers/">
                    Privacy Shield
                  </a>
                  "
                  , and will be used to send out newsletters about
                  {' '}
                  {shop.name}
                  , its services and
                  products relevant to your interests. We will always provide the option to cancel
                  and/or remove you from our databases with each electronic communication you
                  receive from us. You can change your information or preferences at any time or
                  provide additional details.
                </li>
                <li>
                  Information related to the performance of the contract with your organisation,
                  including your employees/attendee’s data, such as name, surname, company,
                  position, address, ID number, email, telephone and billing details. We might also
                  collect data which is consider by current regulation as sensitive, in case you
                  request services which required special needs (food allergies, accommodation for
                  smokers or adapted services for people with a disability).

                </li>
                <li>
                  Information related to requests for the provision of our services, such as name,
                  surname, company, position, address, email and telephone.
                </li>
              </ul>
            </Paragraph>

            <Paragraph>
              Personal data is stored in our databases for the management of commercial relations
              and will be used to complete any electronic requests for services through our website
              and for sending customer satisfaction surveys to users and the services we offer, in
              order to know your opinion and improve them.

            </Paragraph>
            <Paragraph>
              You can change your information or preferences at any time or provide additional
              details.
            </Paragraph>

            <Paragraph>
              We will not use your personal data to make automated decisions that affect or create
              profiles other than those described above. In any event, if there is further
              processing of data for purposes other than those for which they were initially
              collected, we will inform you of all aspects relevant to this other purpose before
              proceeding with such further processing.

            </Paragraph>
            <Divider />
            <Paragraph>
              <Text strong>
                Period for which the personal data will be stored
              </Text>
            </Paragraph>
            <Paragraph>
              Users' personal data will be kept for the duration of pre-contractual negotiations or
              requests for information, and in the case of commercial communications, for as long as
              you do not object to receiving such information. When entering into contract with us,
              personal data must be kept the legal prescription periods required by Spanish
              regulations, 6 years.
            </Paragraph>
            <Paragraph>
              In determining the appropriate retention period for personal data, we also rely on the
              quantity, type and sensitivity of the personal data stored, the potential risk of harm
              from unauthorised use or disclosure of your personal data, the purposes for which we
              process your personal data and the applicable legal requirements.
            </Paragraph>
            <Divider />
            <Paragraph>
              <Text strong>
                Lawfulness to process personal data
              </Text>
            </Paragraph>
            <Paragraph>
              The following legal bases of lawfulness apply to the processing of your personal data:
            </Paragraph>
            <Paragraph>
              <ul>
                <li>
                  <Text strong>
                    Consent
                  </Text>
                  : for data relating to requests for newsletter subscriptions and commercial
                  communications.
                </li>
                <li>
                  <Text strong>
                    Performance of our legal relationship or contract
                  </Text>
                  , when you enter into a contract with us for any of our services.
                </li>
                <li>
                  <Text strong>
                    Legitimate interest
                  </Text>
                  : for data relating to requests for information or services, which make the
                  processing necessary to carry out our commercial activity, provided that such
                  interests are not above your interests or fundamental rights and freedoms. Our
                  legitimate interest is to be able to guarantee business continuity, establishing
                  contact channels that allow us to offer our services and understand the needs and
                  expectations of the market, in addition to improving the level of satisfaction of
                  the Users and, therefore, improve our offer of services and brands.
                  {' '}
                </li>
              </ul>
            </Paragraph>
            <Divider />
            <Paragraph>
              <Text strong>
                Recipients of personal data
              </Text>
            </Paragraph>
            <Paragraph>
              The User's personal data may be transferred/communicated to the following recipients:
            </Paragraph>
            <Paragraph>
              <ul>
                <li>
                  Companies in charge of data processing, such as providers that perform services
                  for
                  {' '}
                  {shop.name}
                  , such as the management of promotional campaigns, content
                  management and customer relations. Service providers are authorised to use the
                  information only to the extent necessary to perform and provide the services for
                  which they were contracted and subject to the terms set forth in the processing
                  contract(s) entered into and in this privacy policy.

                </li>
                <li>
                  Public Administrations and Bodies when required by tax, labor or other applicable
                  regulations.

                </li>
              </ul>
            </Paragraph>
            <Paragraph>
              International data transfers may also occur as a result of the above
              transfers/communications if the recipient companies are located outside the European
              Economic Area, for which
              {' '}
              {shop.name}
              {' '}
              will use the standard contractual clauses adapted
              by the European Commission and the EU-US Privacy Shield scheme, as a guarantee for
              transfers to countries that do not have an adequacy decision from the European
              Commission. In any case, those third parties with whom certain personal data are
              shared will have previously accredited the adoption of adequate technical and
              organisational measures for the correct protection of the data in accordance with the
              GDPR.

            </Paragraph>
            <Paragraph>
              We undertake never to use your personal data for purposes other than those set out in
              this privacy policy, nor to transfer it unlawfully to third parties.

            </Paragraph>
            <Divider />
            <Paragraph>
              <Text strong>
                Rights of data subjects

              </Text>
            </Paragraph>
            <Paragraph>
              Users may exercise any of the rights recognised in the data protection regulations
              before us.
            </Paragraph>
            <Paragraph>
              We undertake to respect and facilitate the exercise of these rights and, in
              particular, the rights of access, rectification, erasure, opposition, restriction of
              processing and portability of data. These rights may be exercised by sending an email
              to privacy@
              {shop.name}
              .com, along with a copy of your valid identification document.

            </Paragraph>
            <Paragraph>
              We will provide you with all the necessary information regarding the actions we carry
              out on the basis of any request to exercise the rights that concern you as an
              interested party, within the stipulated legal deadlines.

            </Paragraph>
            <Paragraph>
              In any case, we inform you that you may lodge a complaint with a data protection
              supervisory authority, in the case of Spain, before the
              "
              <a href="https://www.aepd.es/es/la-agencia/donde-encontrarnos">
                Spanish Data Protection Agency
              </a>
              "
              , for the protection of any of your rights.

            </Paragraph>
            <Divider />
            <Paragraph>
              <Text strong>
                Application of security measures on personal data

              </Text>
            </Paragraph>
            <Paragraph>
              We guarantee that we maintain the levels of security for the protection of personal
              data provided to us through the website in accordance with current legislation, and
              that all technical and organisational measures have been adopted and applied to
              prevent the loss, destruction or accidental damage, misuse, alteration and
              unauthorised or unlawful processing of data provided by users.

            </Paragraph>
            <Paragraph>
              We have also introduced a notification procedure in the event of any suspected breach
              of personal data security, addressed to the data subjects and to the appropriate
              supervisory authority.

            </Paragraph>
            <Divider />
            <Paragraph>
              <Text strong>
                Right to withdraw consent

              </Text>
            </Paragraph>
            <Paragraph>
              If you have given your consent for the processing of your personal data, you have the
              right to withdraw it in whole or in part. To withdraw your consent, please follow the
              opt-out links provided in all commercial communications sent to you or contact us via
              our contact form on this website or via email.

            </Paragraph>
            <Paragraph>
              Once we have received notice that you have withdrawn your consent, we will no longer
              process your information for the purposes for which you originally authorised it,
              unless there are compelling legitimate grounds for further processing that would
              nullify your interests, rights and freedoms or for the exercise or defense of legal
              claims. The withdrawal of consent to receive commercial communications will not affect
              the processing of personal data for the provision of our services.

            </Paragraph>
            <Divider />
            <Paragraph>
              <Text strong>
                Contact

              </Text>
            </Paragraph>
            <Paragraph>
              If you have any questions, comments or complaints about the use of your personal data,
              please contact our customer service department by email at privacy@
              {shop.name}
              .com or
              by telephone (+34) 931785300.

            </Paragraph>
            <Paragraph>
              This privacy policy was last updated in September 2018.

            </Paragraph>

          </PageHeader>
        </Col>
      </Row>
    </BaseLayout>
  );
};

export default PrivacyPage;
